export const surveyJson = {
    title:"Project developer form",
    description:"Decarbonizing the future.",
    logo: "https://ureca.com/logo/logo-original-nosub.png",
    logoPosition:"right",

    
    pages:
      [
        {
          name: "page1",
          elements: [
            {
              type: "text",
              name:"primary_email",
              title:"What is your primary email?",
              inputMask: "email",
              startWithNewLine: false,
              isRequired: true,
              validators: [
                { type: "email" }
              ]
            }
          ],
          title: "Welcome to URECA",
          description: "Please introduce your project."
        },
        {
          name:"page2",
          elements:[
            {
              type:"comment",
              name:"description",
              title:"Project description / short intro, 60-80 words /",
              validators: [
                {
                  type: "expression",
                  expression: "{description} notempty",
                  text: "Please answer this question."
                }
              ]
            },
            {
              type:"file",
              name:"description_files",
              title:"And PDF file that contains a comprehensive information / Please combines the files to 1 ZIP file /",
              storeDataAsText: false,
              showPreview: false,
              allowMultiple: false
            },
            {
              type:"text",
              name:"location",
              title:"Project region, location / GPS coordinate /"
            },
            {
              type:"file",
              name:"pictures",
              title:"Project pictures / up to 5 pictures, Size: 1024x860px, 3:2 scale, please combines the files to 1 ZIP file /",
              storeDataAsText: false,
              showPreview: false,
              allowMultiple: false
            },
            {
              type:"file",
              name:"certificates",
              title:"Copy of Verra/ Gold /other certifications / Please combines the files to 1 ZIP file /",
              storeDataAsText: false,
              showPreview: false,
              allowMultiple: false
            },
            {
              type:"comment",
              name:"more_info",
              title:"Short info about project additionality, permanence, leakage, co-benefits, SDGs / 60-80 words each /"
            },
            {
              type:"text",
              name:"links",
              title:"Project and developer website links"
            },
            {
              type:"text",
              name:"contact",
              title:"The main contact person and URECA primary login email account / Company's general email is preferable /"
            },
            {
              type:"file",
              name:"additional",
              title:"Additional project documents / Optional, please combines the files to 1 ZIP file /",
              storeDataAsText: false,
              showPreview: false,
              allowMultiple: false
            }
          ],
          title:"Project developer form",
          description:"Our mission is to make carbon offsetting accessible for all through innovative technology."
        }
      ]
    };