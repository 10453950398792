// import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
// // import App from './App';
// import SurveyComponent from './SurveyComponent';
// import reportWebVitals from './reportWebVitals';
// import { createRoot } from "react-dom/client";

// // const root = createRoot(document.getElementById("surveyElement"));

// ReactDOM.render(
//   <React.StrictMode>
//     <SurveyComponent />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // // If you want to start measuring performance in your app, pass a function
// // // to log results (for example: reportWebVitals(console.log))
// // // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from "react";
import { createRoot } from "react-dom/client";
import SurveyComponent from "./SurveyComponent";

const div = document.getElementById("root")
const root = createRoot(div);
root.render( <SurveyComponent /> );