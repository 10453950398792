import React from "react";
import S3 from 'react-aws-s3';
import './style.css'
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import axios from "axios";
import { surveyJson } from "./json";


window.Buffer = window.Buffer || require("buffer").Buffer;
StylesManager.applyTheme("defaultV2");

const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS,
    secretAccessKey: process.env.REACT_APP_SECRET,
}
const ReactS3Client = new S3(config);

var new_description_files_url = ''
var new_pictures_url = ''
var new_certificates_url = ''
var new_additional_url = ''

function updatePreview(newValue, container) {
    container.innerHTML = "";
    (newValue || []).forEach(function(fileItem) {
        var button = document.createElement("div");
        button.className = "btn sv-btn sv-file__choose-btn sd-btn sd-btn--action";
        button.innerText = "Download " + fileItem.name;
        button.onclick = function() {
            alert(JSON.stringify(fileItem));
        }
        container.appendChild(button);
    });
}

function SurveyComponent() {
    const survey = new Model(surveyJson);

    survey.onComplete.add(function(sender, options) {
        options.showDataSaving('Your data is being uploaded, please wait...');
        let data = sender.data;
        if (data.primary_email) {
            data.primary_email = data.primary_email.toLowerCase()
        }
        data.description_files_url = new_description_files_url;
        data.pictures_url = new_pictures_url;
        data.certificates_url = new_certificates_url;
        data.additional_url = new_additional_url;
        
        axios.post(
          'https://1d4cwq8g71.execute-api.eu-central-1.amazonaws.com/default/project_developer_form_upload',
          data
        ).then(result => {
          console.log(result)
          if (result.status === 200) {
            options.showDataSavingSuccess('Done.');
          } else {
            options.showDataSavingError('Something went wrong, please reduce the combined file size to 5MB.');
          }
        })
      });

    survey.onCurrentPageChanged.add(function (sender, options) {
        let data = sender.data
        if (data.primary_email) {
            data.primary_email = data.primary_email.toLowerCase()
        }
        axios.post(
            'https://1d4cwq8g71.execute-api.eu-central-1.amazonaws.com/default/project_developer_form_upload',
            data
        ).then(result => {
            console.log(result)
        if (result.status === 200) {
            //   options.showDataSavingSuccess('Done.');
        } else {
            //   options.showDataSavingError('Something went wrong, please reduce the combined file size to 5MB.');
        }
        })
    });

      survey.onAfterRenderQuestion.add(function (sender, options) {
        if(options.question.getType() === "file") {
            var container = document.createElement("div");
            container.className = "my-preview-container";
            var fileElement = options.htmlElement.getElementsByClassName("sv_q_file")[0];
            if(!fileElement) {
                fileElement = options.htmlElement.getElementsByClassName("sv-file__decorator")[0];
            }
            if (!fileElement) {
                fileElement = options.htmlElement.getElementsByClassName("sd-file__decorator")[0];
            }
            fileElement.appendChild(container);
            options.question.onPropertyChanged.add(function(question, options) {
                if(options.name === "value") {
                    updatePreview(options.newValue, container);
                }
            });
            updatePreview(options.question.value, container);
        } else {
        }
    });

    survey.onUploadFiles.add(function(survey, options) {
        console.log('onUploadFiles')
        options.files.forEach(async (file) => {
            console.log(options)
            ReactS3Client.uploadFile(file, file.name)
            .then( data => {
                options.callback('success', options.files.map(function(file) {
                    return {
                        file: file
                    };
                }))
                if (options.name === 'additional') {
                    new_additional_url = data.location;
                    if (data.additional) delete data.additional;
                } else if (options.name === 'certificates') {
                    new_certificates_url = data.location;
                    if (data.certificates) delete data.certificates;
                } else if (options.name === 'pictures') {
                    new_pictures_url = data.location;
                    if (data.pictures) delete data.pictures;
                } else if (options.name === 'description_files') {
                    new_description_files_url = data.location;
                    if (data.description_files) delete data.description_files;
                } 
                console.log(data.location)
            })
            .catch( err => {
                console.error(err)
            })
        });
    })
    return (<Survey model={survey} />);
}

export default SurveyComponent;